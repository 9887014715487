@import "../../assets/styles/utils";
@import "../../assets/styles/common.module";

:global(body.lottie){
  background-color: #e1e7f2;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/bg/lottie_bg_xs.jpg");

  @include min480{
    background-image: url("../../assets/images/bg/lottie_bg_sm.jpg");
  }

  @include min768{
    background-image: url("../../assets/images/bg/lottie_bg_md.jpg");
  }

  @include min1180{
    background-image: url("../../assets/images/bg/lottie_bg_lg.jpg");
  }
}

:global(body.lottie-transparent){
  background-color: #e1e7f2;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/bg/transparent/lottie_bg_xs.jpg");

  @include min480{
    background-image: url("../../assets/images/bg/transparent/lottie_bg_sm.jpg");
  }

  @include min768{
    background-image: url("../../assets/images/bg/transparent/lottie_bg_md.jpg");
  }

  @include min1180{
    background-image: url("../../assets/images/bg/transparent/lottie_bg_lg.jpg");
  }
}

.relative{
  position: relative;
}

.section{
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  z-index: 10;

  &.h401{
    @include min1180{
      min-height: 401px;
    }
  }

  &.h379{
    @include min1180{
      min-height: 379px;
    }
  }

  &.h395{
    @include min1180{
      min-height: 395px;
    }
  }

  &.h336{
    @include min1180{
      min-height: 336px;
    }
  }

  &.h480{
    @include min1180{
      min-height: 480px;
    }
  }

  @include min768{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 80px;
    max-width: max-content;
  }

  @include min1180{
    gap: 180px;
  }

  &.reverse{
    @include min768{
      gap: 100px;
      flex-direction: row-reverse;
    }
  }

  &.main{
    padding-top: 38px;
    max-width: 100%;

    @include min768{
      padding-top: 48px;
      flex-direction: column;
      align-items: start;
      gap:60px;
    }

    @include min1180{
      padding-top: 85px;
      flex-direction: row;
      align-items: center;
      gap:90px;
    }

    .info{
      gap: 12px;

      @include min768{
        gap: 24px;
      }

      @include min1180{
        gap: 24px;
      }
    }

    .title{
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;

      @include min768{
        font-size: 40px;
        font-weight: 600;
        line-height: 46px;

      }

      @include min1180{
        font-size: 48px;
        font-weight: 600;
        line-height: 54px;
      }
    }

    .description{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;

      @include min768{
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
      }

      @include min1180{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;

      }
    }

    .media{
      @include min768{
        width: 670px;
        //margin: 0 auto;
      }

      @include min1180{
        width: 700px;
      }
    }
  }

  &.tag{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 32px;

    @include min1180{
      gap:40px;
    }

    .title{
      width: 100%;
      text-align: center;
    }
  }

  &.news{
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 100%;
    background: linear-gradient(270deg, #1212B7 0%, #1E1F83 100%);
    border-radius: 16px;
    overflow: initial;
    padding: 30px 30px 315px;

    @include min768{
      padding: 40px 50px 485px;
    }

    @include min1180{
      padding: 70px 130px 410px;
    }

    .title{
      color: #fff;
      text-align: left;
      margin-bottom: 32px;
      max-width: 600px;

      @include min768{
        text-align: center;
      }

      @include min1180{
        margin-bottom: 40px;
      }
    }
  }

  &.newsMedia{
    flex-direction: column;
    max-width: 100%;
    margin-top: -345px; // 265 + 80

    @include min768{
      margin-top: -520px; // 400 + 120
      margin-bottom: 120px;
    }

    @include min1180{
      margin-top: -470px; //350 + 120
    }
  }
}

.info{
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 600px;

  @include min768{
      gap:16px;
      width: 345px;
  }

  @include min1180{
    gap: 16px;
    width: 450px;
    flex: initial;
  }
}

.title{
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000;

  @include min768 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
  }

  @include min1180{
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
  }
}

.description{
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  @include min768{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  @include min1180{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
}

.buttons{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: max-content;
  margin-top: 20px;

  @include min768{
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;
  }
}

.btn{
  padding: 0 16px;
  border-radius: 24px;
  background-color: var(--color-link);
  border:2px solid var(--color-link);
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  height: 40px;
  color: #fff;
  box-sizing: border-box;
  display: block;
  text-align: center;
  transition: background-color $transition, border $transition, color $transition;

  @include min768{
    height: 48px;
    line-height: 44px;
    padding: 0 24px;
  }

  //&:hover{
  //  background-color: var(--color-keywords);
  //  border:2px solid var(--color-keywords);
  //}

  &.outline{
    border:2px solid var(--color-link);
    background: var(--color-light);
    color: var(--color-link);
    //
    //&:hover{
    //  border:2px solid var(--color-keywords);
    //  background-color: var(--color-keywords);
    //  color: #fff;
    //}
  }

  &.light{
    border:2px solid var(--color-light);
    background-color: var(--color-light);
    color: var(--color-grey);

    //&:hover{
    //  border:2px solid var(--color-light2);
    //  background-color: var(--color-light2);
    //}
  }
}

.btnIcon{
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  line-height: 1;
  @include margin-right(10px);
}

.media{
  display: block;

  @include min768 {
    width: 345px;
  }

  @include min1180 {
    width: 530px;
  }

  img{
    display: block;
    width: 100%;
    height: 100%;
  }
}

.macbook{
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto;

  @include min768 {
    height: 630px;
    background-image: url("../../assets/images/MacBook.png");
  }
}

.lottieNews{
  @include min768 {
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
  }
}

.lottieTelegram{
  @include min768 {
    position: absolute;
    top: 100px;
    right: 0;
    width: 300px;
  }
}

.slider{
  margin: 0 -12px;
  padding: 0 12px;
  overflow: hidden;

  :global(.swiper){
    overflow: initial;
  }

  :global(.swiper-slide){
    width: 315px;
    margin: 0;

    @include min1180{
      width: 385px;
    }
  }
}

.slide{
  border-radius: 16px;
  background: #F5F5F5;
  padding: 16px 16px 40px;

  @include min1180{
    padding: 24px 24px 50px;
  }
}

.slideIcon{
  width: 56px;
  height: 56px;
  background: var(--color-link);
  border-radius: 50%;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 12px;

  svg{
    fill: #fff;
  }
}

.slideTitle{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

.slideDescription{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #323232;
}

.newsList{
  margin-bottom: 32px;

  @include min768{
    display: flex;
    width: 100%;
    gap: 40px;
  }
}

.newsItem{
  margin-bottom: 24px;
  max-width: 600px;

  @include min768{
    margin-bottom: 0;
  }
}

.newsTitle{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin-bottom: 12px;

  @include min1180{
    font-size: 24px;
    line-height: 32px;
  }
}

.newDescription{
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #DBDEE9;

  @include min1180{
    font-size: 20px;
    line-height: 30px;
  }
}

.newsBtn{
  margin-bottom: 48px;
  width: max-content;

  @include min768{
    margin-bottom: 85px;
  }

  @include min1180{
    margin-bottom: 60px;
  }
}

.newsMediaWrapper{
  padding: 0 30px;

  @include min768{
    padding: 0 50px;
  }

  @include min1180{
    padding: 0 130px;
  }
}

.newsMediaImg{
  margin: 0 auto;

  @include min768{
    width: 565px;
  }

  @include min1180{
    width: 1000px;
  }

  img{
    display: block;
    width: 100%;
    height: auto;
  }
}

.footer{
  border-top: 1px solid var(--color-border);
}

.footerWrapper{
  padding: 32px 10px 16px;
  box-sizing: border-box;
  max-width: 1260px;
  margin: 0 auto;

  @include min1180{
    display: flex;
    align-items: start;
    width: 100%;
    gap: 100px;
    padding: 32px 0 16px;
  }
}

.footerLogo{
  width: 158px;
  svg{
    fill: #003057
  }
}

.footerInfo{
  margin-top: 48px;

  @include min1180{
    margin-top: 0;
  }
}

.footerList{
  margin-bottom: 32px;

  @include min1180{
    width: 100%;
    display: flex;
    gap: 24px;
    margin-bottom: 48px;
  }
}

.footerListItem{
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  color: #000;
  transition: color $transition;

  &:hover{
    color: var(--color-link);
  }

  @include min1180{
    margin: 0;
  }
}

.copyright{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-grey);
}

.ellipse_1{
  position: absolute;
  background-image: url("../../lottie/ellipse/Ellipse_1_mob.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  top: 120px;
  left: -160px;
  width: 400px;
  height: 400px;
}

.ellipse_2{
  position: absolute;
  background-image: url("../../lottie/ellipse/Ellipse_2_mob.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  top: 134px;
  left: -110px;
  width: 500px;
  height: 500px;
}

//.ellipse_91{
//  background: #6A97FF;
//  backdrop-filter: blur(300px)
//}
//
//.ellipse_111{
//  background: #F9F9ED;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_103{
//  background: #F9F9ED;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_119{
//  background: #70A9FF;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_109{
//  background: #6A97FF;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_118{
//  background: #70A9FF;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_112{
//  background: #F9F9ED;
//  backdrop-filter: blur(250px)
//}
//
//.ellipse_110{
//  background: #70A9FF;
//  backdrop-filter: blur(250px)
//}

