@import "./utils";

:root{
	--color-red: #E74A3A;
	--color-grey: #545454;
	--color-light: #E1EFFF;
	--color-light2: #CCDFF3;
	--color-link: #1345AE;
	--color-keywords: #3252B6;
	--color-header: #344370;
	--color-blue: #44579E;
	--color-blue2: #F3FAFE;
	--color-dark: #03143E;
	--color-darkBlue: #03143E;
	--color-main: #354577;
	--color-subscribe: #010f4d;
	--color-background: #FDFEFF;
	--color-warning: #FFFB9C;
	--color-input: #DADDEC;
	--color-border: #5F72AF;
	--color-day-1000: #A5D6F1;
	--color-day-500: #BEE7FF;
	--color-day-350: #BEE7FF;
	--color-day-250: #FFFFFF;
	--color-day-0: #EDF3F6;
}